import React from "react";
import './App.css';


// App.js

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <p>VoteDefense: Coming Soon</p>
            </header>
        </div>
    );
}


export default App;